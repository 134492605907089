import React, {useState, useEffect, useMemo, Fragment} from 'react'

import useComponentVisible from '../hooks/useComponentVisible';
import {filterTranslations} from './filterTranslations';

const Filter = ({filter, handleFilter, getData}) => {
    const [value, setValue] = useState("");
    const [search, setSearch] = useState("");
    const { ref, isComponentVisible } = useComponentVisible(false, filter);

    useEffect(() => {
        if (filter.type === 'switch') {
            setValue(filter.value ? true : false)
        } else {
            setValue(filter.value ? filter.value : '')
        }
    }, [filter.value, filter.type])

    useEffect(() => {
        if (filter.type === "select") {
            const filterSearch = document.getElementById(filter.name+"-search")
            if (filterSearch !== null) {
                filterSearch.focus()
            }
        }
    
    }, [isComponentVisible, filter.type]) //eslint-disable-line
    
    const options = useMemo(() => {
        if (filter.options !== undefined && filter.options !== null) {
            return filter.options.map((option, index) => {
                if (search.length === 0 || option.name.toLowerCase().includes(search.toLowerCase())) {
                    return <li key={filter.name + '-' + index} className="select2-results__option" onClick={() => handleFilter(option.value, filter.name, filter.type)}>{option.name === 'vse' ? filterTranslations[filter.name] : option.name}</li>
                } else {
                    return null
                }
            });
        } else {
            return null;
        }
    }, [filter.options, filter.name, filter.type, search, handleFilter])

    const displayedValue = useMemo(() => {
        let displayedName = filterTranslations[filter.name];
        if (filter.options !== undefined && filter.options !== null) {
            filter.options.forEach(option => {
                if (option.value === value) displayedName = option.name;
            });
        }
        return displayedName;
    }, [value, filter.options]) //eslint-disable-line

    const onKeyUpHandle = (e) => {
        if (e.key === 'Enter' && getData !== undefined) {
            getData(false)
        }
    }

    // Create Fields
    let field = "";
    if (filter.type === "select") {
        field = (
            <Fragment>
                <span ref={ref} className="select2 select2-container select2-container--default select2-container--below" /* select2-container--open */ style={{ width: '100%', position: 'relative' }}>
                    <span className="selection">
                        <span className="select2-selection select2-selection--single">
                            <span className="select2-selection__rendered" id={filter.name} title="Vse regije">{displayedValue}</span>
                            <span className="select2-selection__arrow"><b className={isComponentVisible ? 'open' : ''}></b></span>
                        </span>
                    </span>
                    <span className="dropdown-wrapper" aria-hidden="true"></span>
                    {/* DROPDOWN */}
                    <div className="select-filter-dropdown" style={{ display: isComponentVisible ? 'block' : 'none' }}>
                        <span className="select2-container select2-container--default select2-container--open">
                            <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: '288px' }}>
                                <span className="select2-search select2-search--dropdown">
                                    <input className="select2-search__field" id={filter.name+"-search"} value={search} onChange={(e) => setSearch(e.target.value)} />
                                </span>
                                <span className="select2-results">
                                    <ul className="select2-results__options" id="select2-filter-regije-results">
                                        {options}
                                    </ul>
                                </span>
                            </span>
                        </span>
                    </div>
                </span>
            </Fragment>
        )
    } else if (filter.type === "text") {
        field = <input name={filter.name} className="form-control" id={filter.name} type="text" value={value} onChange={(e) => handleFilter(e.target.value, filter.name, filter.type)} onKeyUp={(e) => onKeyUpHandle(e)} />
    } else if (filter.type === "switch") {
        if (value.length !== 0) {
            field = <Fragment>
                <input name={filter.name} id={filter.name} type="checkbox" defaultChecked={value} value={value} onChange={() => handleFilter(!value, filter.name, filter.type)} />
                <label htmlFor={filter.name} className="control-label">Aktiven</label>
            </Fragment>
        }
    }

    return (
        <div className="form-group form-group-sm col-sm-12 col-md-6">
            <label htmlFor={filter.name} className="col-sm-4 control-label">{filter.displayName}</label>
            <div className="col-sm-8">
                {field}
            </div>
        </div>
    )
}

export default Filter