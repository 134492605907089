import React, { useEffect, useState, useMemo } from 'react'

import ProgressBar from '../../hoc/progressBar/ProgressBar';
import { number_format } from '../../izUtils';

const JoinedTable = ({data, type}) => {
    const [joinedOccupation, setJoinedOccupation] = useState(null);

    useEffect(() => {
        let columns = {};
        if (data !== null && data[type] !== null) {
            data[type].values.forEach(row => {
                row.forEach((item) => {
                    if (!columns[item.column]) columns[item.column] = [];
                    columns[item.column][item.rowID] = item;
                })
            })
        }
        setJoinedOccupation(columns);
    }, [data, data.joinedOccupation]) // eslint-disable-line

    // Count the joinedOccupation
    let together = useMemo(() => {
        if (joinedOccupation !== null) {
            let keys = ["ST_ODDELKOV", "ST_OTROK", "ST_PROSTIH_MEST"]
            let togetherColumn = []; 
    
            keys.forEach((row, index) => {
                if (joinedOccupation[row]) {
                    let count = 0;
                    joinedOccupation[row].forEach(item => {
                        count += item.value;
                    })
                    togetherColumn.push(<td key={index + '_together' } className={row === 'FLEKS_DVA_ST_OTROK' ? 'strongBorderR' : ''} >{number_format(count)}</td>);
                }
            });
    
            return (
                <tr>
                    <td>Skupaj</td>
                    <td className="strongBorderR"></td>
                    {togetherColumn}
                </tr>
            )
        } else return {}
    }, [joinedOccupation])

    
    if (joinedOccupation !== null) {
        if (Object.keys(joinedOccupation).length === 0) return "No joinedOccupation"
        return (
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th className="strongBorderB">Starostno obdobje</th>
                        <th className="strongBorderB strongBorderR">Oznaka oddelka</th>
                        <th className="strongBorderB">Število oddelkov</th>
                        <th className="strongBorderB">Število otrok</th>
                        <th className="strongBorderB">Število prostih mest</th>
                    </tr>
                </thead>
                <tbody>
                    {/* PRVO STAROSTNO OBDOBJE */}
                    <tr className="orgGroup1">
                        <td className="strongBorderB" rowSpan="3"><strong>1. starostno obdobje</strong></td>
                        <td className="strongBorderR">1-2</td>
                        <td>{number_format(joinedOccupation['ST_ODDELKOV'][0].value)}</td>
                        <td>{number_format(joinedOccupation['ST_OTROK'][0].value)}</td>
                        <td>{number_format(joinedOccupation['ST_PROSTIH_MEST'][0].value)}</td>
                    </tr>
                    <tr className="orgGroup1">
                        <td className="strongBorderR">2-3</td>
                        <td>{number_format(joinedOccupation['ST_ODDELKOV'][1].value)}</td>
                        <td>{number_format(joinedOccupation['ST_OTROK'][1].value)}</td>
                        <td>{number_format(joinedOccupation['ST_PROSTIH_MEST'][1].value)}</td>
                    </tr>
                    <tr className="orgGroup1">
                        <td className="strongBorderB strongBorderR">1-3</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_ODDELKOV'][2].value)}</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_OTROK'][2].value)}</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_PROSTIH_MEST'][2].value)}</td>
                    </tr>
                    {/* DRUGO STAROSTNO OBDOBJE */}
                    <tr className="orgGroup2">
                        <td className="strongBorderB" rowSpan="4"><strong>2. starostno obdobje</strong></td>
                        <td className="strongBorderR">3-4</td>
                        <td>{number_format(joinedOccupation['ST_ODDELKOV'][3].value)}</td>
                        <td>{number_format(joinedOccupation['ST_OTROK'][3].value)}</td>
                        <td>{number_format(joinedOccupation['ST_PROSTIH_MEST'][3].value)}</td>
                    </tr>
                    <tr className="orgGroup2">
                        <td className="strongBorderR">4-5</td>
                        <td>{number_format(joinedOccupation['ST_ODDELKOV'][4].value)}</td>
                        <td>{number_format(joinedOccupation['ST_OTROK'][4].value)}</td>
                        <td>{number_format(joinedOccupation['ST_PROSTIH_MEST'][4].value)}</td>
                    </tr>
                    <tr className="orgGroup2">
                        <td className="strongBorderR">5-6</td>
                        <td>{number_format(joinedOccupation['ST_ODDELKOV'][5].value)}</td>
                        <td>{number_format(joinedOccupation['ST_OTROK'][5].value)}</td>
                        <td>{number_format(joinedOccupation['ST_PROSTIH_MEST'][5].value)}</td>
                    </tr>
                    <tr className="orgGroup2">
                        <td className="strongBorderB strongBorderR">3-6</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_ODDELKOV'][6].value)}</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_OTROK'][6].value)}</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_PROSTIH_MEST'][6].value)}</td>
                    </tr>
                    {/* KOMBINIRANI ODDELKI */}
                    <tr className="orgGroup3">
                        <td className="strongBorderB" rowSpan="2"><strong>Kombinirani oddelki</strong></td>
                        <td className="strongBorderR">1. star. obdobje</td>
                        <td className="strongBorderB" rowSpan="2">{number_format(joinedOccupation['ST_ODDELKOV'][7].value)}</td>
                        <td className="strongBorderB" rowSpan="2">{number_format(joinedOccupation['ST_OTROK'][7].value)}</td>
                        <td>{number_format(joinedOccupation['ST_PROSTIH_MEST'][7].value)}</td>
                    </tr>
                    <tr className="orgGroup3">
                        <td className="strongBorderB strongBorderR">2. star. obdobje</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_PROSTIH_MEST'][8].value)}</td>
                    </tr>
                    {/* RAZVOJNI ODDELKI */}
                    <tr className="orgGroup4">
                        <td className="strongBorderB"><strong>Razvojni oddelki</strong></td>
                        <td className="strongBorderB strongBorderR"></td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_ODDELKOV'][9].value)}</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_OTROK'][9].value)}</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_PROSTIH_MEST'][9].value)}</td>
                    </tr>
                    {/* VZGOJNO VARSTVENA DRUŽINA */}
                    <tr className="orgGroup5">
                        <td className="strongBorderB" rowSpan="2"><strong>Vzgojno-varstvena družina</strong></td>
                        <td className="strongBorderR">1-3</td>
                        <td className="strongBorderB" rowSpan="2">{number_format(joinedOccupation['ST_ODDELKOV'][10].value)}</td>
                        <td className="strongBorderB" rowSpan="2">{number_format(joinedOccupation['ST_OTROK'][10].value)}</td>
                        <td>{number_format(joinedOccupation['ST_PROSTIH_MEST'][10].value)}</td>
                    </tr>
                    <tr className="orgGroup5">
                        <td className="strongBorderB strongBorderR">3-6</td>
                        <td className="strongBorderB">{number_format(joinedOccupation['ST_PROSTIH_MEST'][11].value)}</td>
                    </tr>
                    {/* SKUPAJ */}
                </tbody>
                <tfoot>
                    {together}
                </tfoot>
            </table>
        )
    } else {
        return <ProgressBar />
    }
}

export default JoinedTable