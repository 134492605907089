import React from 'react'

const DateFilter = ({validOn, changeDate, data}) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    // Years
    let optionsYears = [];
    if (currentMonth === 12) {
        optionsYears.push(
            <option key={"year-"+currentYear+1} value={currentYear+1}>{currentYear+1}</option>
        )
    }
    if (data.pricelist.firstValid !== null) {
        const startYear = (new Date(data.pricelist.firstValid)).getFullYear()
        for (let i = currentYear; i >= startYear; i-- ) {
            optionsYears.push(<option key={"year-"+i} value={i}>{i}</option>)
        }
    } else {
        optionsYears.push(<option key={"year-"+currentYear} value={currentYear}>{currentYear}</option>)
    }

    // Months
    let months = ["januar", "februar", "marec", "april", "maj", "junij", "julij", "avgust", "september", "oktober", "november", "december"];
    let optionsMonths = [];
    if (validOn.getFullYear() < currentYear) {
        for (let i = 0; i <= months.length - 1; i++ ) {
            optionsMonths.push(<option key={"month-"+i} value={i}>{months[i]}</option>)
        }
    } else if (validOn.getFullYear() === currentYear) {
        for (let i = 0; i <= (currentMonth === 12 ? currentMonth - 1 : currentMonth); i++ ) {
            optionsMonths.push(<option key={"month-"+i} value={i}>{months[i]}</option>)
        }
    } else {
        optionsMonths.push(<option key={"month-0"} value={"0"}>januar</option>)
    }

    return (
        <div className="form-group">
            <div className="input-group" style={{ marginRight: '4px' }}>
                <span className="input-group-addon">Leto</span>
                <select className="form-control" value={validOn.getFullYear()+''} onChange={(e) => changeDate(e, 'year')}>
                    {optionsYears}
                </select>
            </div>
            <div className="input-group" style={{ marginRight: '4px' }}>
                <span className="input-group-addon">Mesec</span>
                <select className="form-control" value={validOn.getMonth()+''} onChange={(e) => changeDate(e, 'month')}>
                    {optionsMonths}
                </select>
            </div>
        </div>
    )
}

export default DateFilter