export const disableInputs = (validOn) => {
    let disabled = false;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const selectedYear = new Date(validOn).getFullYear();
    const selectedMonth = new Date(validOn).getMonth() + 1;
    const monthDiff = ( ( (selectedYear - currentYear) * 12 ) + (selectedMonth - currentMonth) );
    if (Math.abs(monthDiff) > 1) disabled = true;

    return disabled;
}