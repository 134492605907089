import React from 'react'

const ProgressBar = () => {
    return (
        <div className="progress">
            <div className="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}>
                <span className="sr-only">100% Complete</span>
            </div>
        </div>
    )
}

export default ProgressBar