import React from 'react'
import { Link } from 'react-router-dom'
import env from '../../env/src_config'

const TableElement = ({data, handleClick}) => {
    const routePrefix = env.spa_home;

    return (
        <tr>
            <td>{data.prs + ' ' + data.mss}</td>
            <td><Link to={routePrefix + "prostaMesta/" + data.id} onClick={() => handleClick(data)}>{data.name}</Link></td>
            <td>{data.hasOwnPricelist ? <Link to={routePrefix + "cenik/" + data.id} onClick={() => handleClick(data)}>Cenik</Link> : null}</td>
        </tr>
    )
}

export default TableElement