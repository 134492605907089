export const number_format = (number, separator = '.') => {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    } else {
        return 0
    }
}

export const number_format2 = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const price_format = (number) => {
    if (number) {
        return parseFloat(number).toFixed(2).toString().replace('.', ',')
    } else {
        return '0,00'
    }
}

export const date_format = (date, type, withSeconds) => {
    const newDate = new Date(date);

    const year = newDate.getFullYear()+'';
    let month = newDate.getMonth() + 1+'';
    let day = newDate.getDate()+'';
    let hours = newDate.getHours()+'';
    let minutes = newDate.getMinutes()+'';
    let seconds = newDate.getSeconds()+''

    if (month.length === 1) month = '0' + month;
    if (day.length === 1) day = '0' + day;
    if (hours.length === 1) hours = '0' + hours;
    if (minutes.length === 1) minutes = '0' + minutes;
    if (seconds.length === 1) seconds = '0' + seconds;


    if (type === 'date') {
        return day + "." + month + "." + year;
    } else if (type === 'time') {
        return hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    } else {
        return day + "." + month + "." + year + " " + hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    }
}

export const findGetParameter = (parameterName) => {
    let result = null;
    let tmp = [];
    let items = window.location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

export const handleError = (error) => {
    if (error !== null) {
        switch (error.code) {
            case "mandatory":
                return "Polje je obvezno"
            case "three_digits":
                return "Število otrok mora biti pozitivno, celo, največ 3 mestno, število"
            case "limit":
                return "Število otrok je preveliko glede na vpisano število oddelkov"
            case "too_few_enrolled":
                return "Število oddelkov je preveliko glede na vpisano število otrok"
            default:
                return error.message;
        }
    } else {
        return null;
    }
}

export const isNull = (value) => {
    return (value === undefined || value === null || value === 'null');
}

export const priceWarningFormat = (price) => {
    let priceSplit = price.split('.');
    priceSplit[1] = priceSplit[1].slice(0, 2);
    let newPrice = priceSplit.join(',')
    return newPrice + '€'
}