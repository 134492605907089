import React, { Fragment, useEffect, useState, useMemo } from 'react'

const Select = ({data, handleChange}) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        setValue(data.value === null ? '' : data.value)
    }, [data.value])
    
    const options = useMemo(() => {
        return data.options.map(option => (
            <option key={option.value} value={option.value}>{option.name}</option>
        ));
    }, [data.options])

    const handleSelect = (e) => {
        let val = parseInt(e.target.value)
        handleChange(isNaN(val) ? '' : val, data)
    }

    return (
        <Fragment>
            <select 
                className="form-control input-sm"
                id={data.column + '-' + data.rowID}
                name={data.column + '-' + data.rowID}
                value={value}
                onChange={handleSelect}
            >
                {options}
            </select>
        </Fragment>
    )
}

export default Select