import React, { useMemo, Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";

import env from '../../env/src_config';
import { useInstStore, useNotificationStore } from '../../store'

const Header = () => {
    const [cookies] = useCookies(['token']);
    let location = useLocation();

    const { institution, setInstitution, hasPricelist, setHasPricelist, hasJoined, setHasJoined } = useInstStore((state) => ({
        institution: state.institution,
        setInstitution: state.setInstitution,
        hasPricelist: state.hasPricelist,
        setHasPricelist: state.setHasPricelist,
        hasJoined: state.hasJoined,
        setHasJoined: state.setHasJoined,
    }))

    const { notification, setNotification } = useNotificationStore((state) => ({
        notification: state.notification,
        setNotification: state.setNotification
    }))

    useEffect(() => {
        if (notification !== null) {
            setNotification(null)
        }

        if (location.pathname === '/') {
            resetParams()
        }
    }, [location.pathname]) // eslint-disable-line

    const resetParams = () => {
        setInstitution(null)
        setHasPricelist(false)
        setHasJoined(false)
    }

    const activeStyle = {
        backgroundColor: '#6fa71f',
        color: '#fff',
    }

    const decoded = useMemo(() =>  jwt_decode(cookies.token), [cookies.token]);
    const routePrefix = env.spa_home;

    return (
        <div id="header" className="navbar navbar-inverse navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <NavLink className="navbar-brand" to="/">Prosta mesta PŠV</NavLink>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav">
                        <li><NavLink to={"/"} style={({ isActive }) => isActive ? activeStyle : undefined }>Zavodi</NavLink></li>
                        {institution !== null &&
                            <Fragment>
                                <li><NavLink to={routePrefix + "prostaMesta/" + institution} style={({ isActive }) => isActive ? activeStyle : undefined }>Prosta mesta</NavLink></li>
                                {hasPricelist && <li><NavLink to={routePrefix + "cenik/" + institution} style={({ isActive }) => isActive ? activeStyle : undefined }>Cenik</NavLink></li>}
                                {hasJoined && <li><NavLink to={routePrefix + "zbirnik/" + institution} style={({ isActive }) => isActive ? activeStyle : undefined }>Zbirnik</NavLink></li>}
                            </Fragment>
                        }
                    </ul>
                    <ul className="nav navbar-nav navbar-right">
                        <li><NavLink to={routePrefix + "pomoc"} target="_blank" rel="noreferrer">Pomoč</NavLink></li>
                        {decoded !== null && <li><NavLink to={routePrefix}>{decoded.Name + ' ' + decoded.Surname}</NavLink></li>}
                    </ul>
                </div>
            </div>
            {notification !== null &&
                <div className={`alert alert-dismissible alert-${notification.type}`}>
                    <div className="container">
                        <p>{notification.text}</p>
                        <button className="close" onClick={() => setNotification(null)}>×</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Header