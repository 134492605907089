import React, { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import Swal from "sweetalert2"

import env from '../../env/src_config'
import ProgressBar from '../../hoc/progressBar/ProgressBar'
import { ErrorResponse } from '../../hoc/errorResponse/ErrorResponse'
import { useInstStore, useNotificationStore } from '../../store'
import PriceTable from './PriceTable'
import { disableInputs } from '../../hoc/helpers/disableInputs'
import DateFilter from '../../hoc/helpers/dateFilter'
import { priceWarningFormat, isNull } from '../../izUtils'

const PriceList = ({options}) => {
    const { id } = useParams()

    const { setInstitution, setHasPricelist, setHasJoined } = useInstStore((state) => ({
        setInstitution: state.setInstitution,
        setHasPricelist: state.setHasPricelist,
        setHasJoined: state.setHasJoined
    }))

    const { setNotification } = useNotificationStore((state) => ({
        setNotification: state.setNotification,
    }))

    const [validOn, setValidOn] = useState(moment(new Date()).toDate())
    const [data, setData] = useState(null);
    const [pricelist, setPricelist] = useState(null);

    useEffect(() => {
        getData(true, false, null, false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, options])

    const getData = (firstTime, save, passedInputs, ignoreWarnings) => {
        let payload = {}
        if (firstTime) {
            payload = {
                id: parseInt(id),
                validOn: moment(validOn).format()
            }
        } else {
            payload = {
                save,
                ignoreWarnings,
                id: parseInt(id),
                hasSections: data.hasSections,
                pricelist: {
                    values: []
                }
            }
    
            const keys = Object.keys(passedInputs)
            let rows = [];
    
            keys.forEach(key => {
                passedInputs[key].forEach(item => {
                    if ((item.value !== null && item.value.length !== '') || (item.validSince !== null && item.validSince.length !== 0)) {
                        if (!rows[item.rowID]) rows[item.rowID] = [];
                        rows[item.rowID].push({
                            ageGroup: item.ageGroup,
                            department: item.department,
                            groupType: item.groupType,
                            column: item.column,
                            unusedCost: item.unusedCost,
                            validSince: item.validSince,
                            value: item.value
                        })
                    }
                })
            })
    
            rows.forEach(item => {
                if (item.length > 0) {
                    payload.pricelist.values.push(item)
                }
            })
        }
        
        let method = 'patch'
        if (firstTime) method = 'post'
        axios[method](env.api + 'admin/enrollment', payload, options).then(response => {
            if (firstTime) {
                setInstitution(id)
                setHasPricelist(response.data.hasOwnPricelist)
                setHasJoined(response.data.topInstitution === parseInt(id))
            }

            let columns = {};
            if (response.data !== null && !isNull(response.data.pricelist)) {
                response.data.pricelist.values.forEach(row => {
                    row.forEach((item) => {
                        if (!columns[item.column]) columns[item.column] = [];
                        if (response.data.updated) item.error = null; // Remove errors when data is updated (saved successfully)
                        columns[item.column][item.rowID] = item;
                    })
                })
            }

            if (firstTime) setData(response.data)
            setPricelist(columns);

            if (save) {
                if (response.data.updated) {
                    setNotification({ text: "Shranjevanje uspešno", type: 'info' })
                    setTimeout(() => {
                        setNotification(null)
                    }, 3000);
                } else {
                    // Check for error code 'over_maximum' to display warning
                    let showError = true;
                    let message = '';
                    response.data.pricelist.values.forEach(row => {
                        for (let item in row) {
                            if (!isNull(row[item].error) && row[item].error.code === 'over_maximum') {
                                let messageSplit = row[item].error.message.split(' ');
                                if (messageSplit[messageSplit.length - 1].indexOf('.') !== -1) {
                                    let price = priceWarningFormat(messageSplit[messageSplit.length - 1]);
                                    messageSplit[messageSplit.length - 1] = price;
                                }
                                message = messageSplit.join(' ')
                                showError = false;
                                break;
                            }
                        }

                    })

                    if (showError) {
                        setNotification({ text: "Shranjevanje se ni izvedlo. Preverite vpisane vrednosti - eno ali več polj ima vpisano neustrezno vrednost", type: 'warning' })
                    } else {
                        Swal.fire({
                            title: "Obvestilo",
                            html: message,
                            icon: "warning",
                            confirmButtonText: "Nadaljuj",
                            showCancelButton: true,
                            cancelButtonText: "Prekliči",
                        }).then(val => {
                            if (val.isConfirmed) {
                                getData(false, true, columns, true)
                            }
                        });
                    }
                }
            }
        })
        .catch(error => {
            ErrorResponse(error)
        })
    }

    const handleChange = (value, params) => {
        let clonePricelist = {...pricelist}
        clonePricelist[params.column][params.rowID].value = parseFloat(value);
        getData(false, false, clonePricelist, false)
    }

    const handleDate = (date, params) => {
        let clonePricelist = {...pricelist}
        clonePricelist[params.column][params.rowID].validSince = date;
        getData(false, false, clonePricelist, false)
    }

    const changeDate = (e, type) => {
        let date = new Date(validOn);
        if (type === 'year') {
            date.setFullYear(e.target.value)
        } else {
            date.setMonth(e.target.value)
        }
        setValidOn(date)
    }

    if (data !== null) {
        const disabled = disableInputs(data.pricelist.validOn);

        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-7">
                        <h3>{data.name}&nbsp;({data.prs + ' ' + data.mss})</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="contactInfo">{data.address.string}</div>
                        <div className="contactInfo">Telefonska številka: {data.telephone.length !== 0 ? data.telephone : "/" }, E-mail: {data.email.length !== 0 ? data.email : "/" }</div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-inline pull-right-md">
                            <DateFilter validOn={validOn} changeDate={(e, type) => changeDate(e, type)} data={data} />
                            <button className="btn btn-primary" onClick={() => getData(true, false, null, false)}>Prikaži</button>
                        </div>
                    </div>
                </div>

                <div id="cenik" className='contentBox'>
                    <hr />
                    <div className="row">
                        <h2 className="col-xs-6" style={{ marginTop: '0' }}> {new Date(data.pricelist.validOn).toLocaleString('sl-SI', { month: 'long' })}&nbsp;{new Date(data.pricelist.validOn).getFullYear()}</h2>
                        {!disabled && 
                            <div className="col-xs-6 text-right">
                                <button className="btn btn-primary" onClick={() => getData(false, true, pricelist, false)}>Shrani</button>
                            </div>
                        }
                    </div>
                    <PriceTable pricelist={pricelist} handleChange={handleChange} handleDate={handleDate} disabled={disabled} validOn={data.pricelist.validOn}/>
                    {!disabled && 
                        <div className="row">
                            <div className="col-sm-12 text-right">
                                <button className="btn btn-primary" onClick={() => getData(false, true, pricelist, false)}>Shrani</button>
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        )
    } else {
        return <ProgressBar />
    }
}

export default PriceList