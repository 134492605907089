import React, { Fragment, useEffect, useState } from 'react'
import DatePicker from "react-datepicker"
import moment from 'moment'
import { isNull, priceWarningFormat } from '../../izUtils'

const DateInput = ({data, handleChange, disabled, validOn, onlyFirstDay = false}) => {
    const [startDate, setStartDate] = useState('');

    useEffect(() => {
        setStartDate(data.validSince === null ? '' : moment(data.validSince).toDate())
    }, [data.validSince])

    const handleDate = (date) => {
        if (date === null) {
            setStartDate('')
            handleChange(date, data);
        } else {
            setStartDate(date)
            handleChange(moment(date).format(), data);
        }
    };

    const firstDay = new Date(new Date(validOn).getFullYear(), new Date(validOn).getMonth(), 1);

    let errorMessage = null;
    if (!isNull(data.error)) {
        errorMessage = data.error.message
        if (data.error.code === 'over_maximum') {
            let messageSplit = data.error.message.split(' ');
            if (messageSplit[messageSplit.length - 1].indexOf('.') !== -1) {
                let price = priceWarningFormat(messageSplit[messageSplit.length - 1]);
                messageSplit[messageSplit.length - 1] = price;
            }
            errorMessage = messageSplit.join(' ')
        }
    }

    return (
        <Fragment>
            <DatePicker 
                className="form-control input-sm psv-datepicker text-box single-line hasDatepicker"
                selected={startDate}
                onChange={handleDate}
                dateFormat='dd.MM.yyyy'
                calendarStartDay={1}
                disabled={disabled}
                includeDates={onlyFirstDay && [firstDay]}
                showYearDropdown={true}
                yearDropdownItemNumber={15}
                scrollableYearDropdown={true}
            />
            {errorMessage !== null &&
                <div className="field-validation-valid text-danger">{errorMessage}</div>
            }
        </Fragment>
    )
}

export default DateInput