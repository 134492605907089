import React, { Fragment, useEffect, useState } from 'react'
import { number_format2, isNull, priceWarningFormat } from '../../izUtils'

const Input = ({data, handleChange, price, disabled}) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        setValue(data.value === null ? '' : ( price ? number_format2(data.value, 2, ',', '.') : data.value ))
    }, [data.value]) // eslint-disable-line

    const handleBlur = (e) => {
        if (price) {
            let val = e.target.value.replace(/\./g, '').replace(',', '.');
            setValue(number_format2(val, 2, ',', '.'))
            handleChange(val, data)
        } else {
            let val = parseInt(e.target.value)
            setValue(isNaN(val) ? '' : val)
            handleChange(isNaN(val) ? '' : val, data)
        }
    }


    let errorMessage = null;
    if (!isNull(data.error)) {
        errorMessage = data.error.message
        if (data.error.code === 'over_maximum') {
            let messageSplit = data.error.message.split(' ');
            if (messageSplit[messageSplit.length - 1].indexOf('.') !== -1) {
                let price = priceWarningFormat(messageSplit[messageSplit.length - 1]);
                messageSplit[messageSplit.length - 1] = price;
            }
            errorMessage = messageSplit.join(' ')
        }
    }

    return (
        <Fragment>
            <div className="input-group">
                <input
                    className="form-control input-sm text-box single-line"
                    id={data.column + '-' + data.rowID}
                    name={data.column + '-' + data.rowID}
                    type="text"
                    value={value}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={disabled}
                />
                {price && <span className="input-group-addon input-sm">€</span>}
            </div>
            {errorMessage !== null &&
                <div className="field-validation-valid text-danger">{errorMessage}</div>
            }
        </Fragment>
    )
}

export default Input