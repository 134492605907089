import React, { Fragment, useState, useRef, useMemo } from 'react'

import TableElement from './TableElement'
import { Pagination } from './Pagination'
import ProgressBar from '../progressBar/ProgressBar'
import { useInstStore } from '../../store'

const List = ({list, pagination}) => {
    const title = useRef(null)
    const { setInstitution } = useInstStore((state) => ({
        setInstitution: state.setInstitution,
    }))

    const [showData, setShowData] = useState(true)
    const [page, setPage] = useState(1)

    const expand = () => {
        title.current.classList.toggle("opened")
        setShowData(!showData)
    }

    const setPagination = (page) => {
        setPage(page)
        pagination.page = page
    }

    const handleClick = (data) => {
        setInstitution(data.id)
        localStorage.setItem('NAJAVA_PSV.Institution', JSON.stringify(data))
    }

    let tableElements = useMemo(() => {
        if (list !== null) {
            return list.slice((page*20-20), page*20).map(element => <TableElement key={element.id} data={element} handleClick={handleClick}/>)
        } else {
            return [];
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, page])

    return (
        <Fragment>
            <h2 className="expandableHeader" onClick={expand} ref={title}><span className="caret caretBig"></span>Seznam zavodov</h2>
            {list === null ? 
                <ProgressBar />
                :
                showData && 
                    <div className="contentBox">
                        <table className='tableList'>
                            <tbody>
                                {tableElements}
                            </tbody>
                        </table>
                        <Pagination pagination={pagination} setPagination={setPagination}/>
                    </div>
            }
        </Fragment>
    )
}

export default List