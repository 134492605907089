import React from 'react'
import image_1 from '../assets/images/01Vstop.jpg'
import image_2 from '../assets/images/02Filter.jpg'
import image_3 from '../assets/images/03Povzetek.jpg'
import image_4 from '../assets/images/04Seznam_zavodov.jpg'
import image_5 from '../assets/images/05Prosta_mesta.jpg'
import image_6 from '../assets/images/06Prosta_mesta_napaka.jpg'
import image_7 from '../assets/images/07Prosta_mesta_shranjeno.jpg'
import image_8 from '../assets/images/08Zadnja_sprememba.jpg'
import image_9 from '../assets/images/09Prosta_mesta_na_dan.jpg'
import image_10 from '../assets/images/10Enota_z_oddelki.jpg'
import image_11 from '../assets/images/11Cenik.jpg'
import image_12 from '../assets/images/12Prehrana_preseganje.jpg'
import image_13 from '../assets/images/13Zbirnik.png'
import image_14 from '../assets/images/14Podpora.jpg'

const Help = () => {
    return (
        <div id="pomoc-container">
            <ul>
                <li><a href="#vstopnaStran">VSTOPNA STRAN APLIKACIJE</a></li>
                <li><a href="#Zavodi">ZAVODI</a></li>
                <ul>
                    <li><a href="#zavodFilter">Filter</a></li>
                    <li><a href="#zavodPovzetek">Povzetek</a></li>
                    <li><a href="#zavodSeznam">Seznam zavodov</a></li>
                </ul>
                <li><a href="#ProstaMesta">PROSTA MESTA</a></li>
                <ul>
                    <li><a href="#prostaMestaStanjeKapacitet">Stanje kapacitet</a></li>
                    <li><a href="#prostaMestaFleksiNormativ">Fleksibilni normativ</a></li>
                    <li><a href="#prostaMestaStanjeNaDan">Stanje kapacitet na dan</a></li>
                </ul>
                <li><a href="#Cenik">CENIK</a></li>
                <li><a href="#Zbirnik">ZBIRNIK</a></li>
                <li><a href="#tehnicnaPodpora">VSEBINSKA IN TEHNIČNA PODPORA</a></li>
            </ul>

            <div className="page-header">
                <h1 id="vstopnaStran">Vstopna stran aplikacije</h1>
            </div>
            <p>
                Pri vstopu v aplikacijo se odpre pregledna stran s številom prostih mest, cenikom in povezavami do posameznih vrtcev. S klikom v vrstico  Filter (viden le uporabnikom v vlogi administratorja),   Povzetek ali   Seznam zavodov lahko posamezen odsek skrijemo ali prikažemo.
            </p>
            <img className="img-responsive img-thumbnail" src={image_1} alt="01Vstop1" />

            {/* <!-- ZAVODI --> */}
            <div className="page-header">
                <h1 id="Zavodi">Zavodi</h1>
            </div>

            <h3 id="zavodFilter">Filter</h3>
            <p>
                <b>Filter</b> je viden uporabnikom v vlogi administratorja. S pomočjo filtra lahko omejimo nabor iskanih zavodov.
            </p>
            <img className="img-responsive img-thumbnail" src={image_2} alt="02Filter" />
            <p>
                Nabor lahko omejimo po Regiji, Občini, Pravnem statusu ali Programu. Posamezen zavod lahko poiščemo z vnosom naziva ali dela naziva zavoda ter klikom na gumb <b>Prikaži</b> oziroma pritiskom tipke Enter. Privzeto bodo prikazani aktivni zavodi, v kolikor pa kljukico iz polja Aktiven odstranimo, bodo vidni podatki tudi neaktivnih zavodov.
            </p>

            <h3 id="zavodPovzetek">Povzetek</h3>
            <p>
                Prikazana tabela vsebuje podatke o zasedenosti, prostih mestih ter povprečnih cenah, na dnu strani pa še povprečna cena neporabljenih živil na dan odsotnosti. Uporabniki, ki imajo dostop le do določenega zavoda vidijo zbirnik podatkov tega zavoda. Uporabnikih v vlogi administratorja vidijo zbirnik podatkov glede na pogoje v filtru oziroma podatke celotne Slovenije, v kolikor je filter prazen.
            </p>
            <p>
                Desno nad tabelo se nahaja gumb Excel, ki omogoča izvoz podatkov tabele v MS Excel oz. Word.
            </p>
            <img className="img-responsive img-thumbnail" src={image_3} alt="03Povzetek" />

            <h3 id="zavodSeznam">Seznam zavodov</h3>
            <p>
                Seznam zavodov vsebuje abecedni nabor zavodov, glede na pogoje v filtru oz. celotno Slovenijo, v kolikor je filter prazen.
            </p>
            <img className="img-responsive img-thumbnail" src={image_4} alt="04Seznam_zavodov" />
            <p>
                Poleg naziva vrtca oz. enote se v vrstici nahaja podatek o matični številki in cenik, v kolikor ima vrtec oz. enota cenik. Po kliku na ime vrtca, se odpre zaslon Prosta mesta, po kliku na Cenik pa zaslon s cenikom.
            </p>

            {/* <!-- PROSTA MESTA --> */}
            <div className="page-header">
                <h1 id="ProstaMesta">Prosta mesta</h1>
            </div>
            <p>
                V tabelo Prosta mesta vnašamo podatke o trenutnih prostih mestih za izbran vrtec oz. njegovo enoto. V primeru, da ima vrtec več enot, vnesete prosta mesta za vsako enoto posebej tako, da na prvi strani aplikacije izberete ustrezno enoto in nato vnesete podatke. V kolikor enota nima oddelkov, je to potrebno označiti na vrhu tabele in v to enoto se stanje ne vnaša, temveč je prikazan zbirnik podatkov, če gre za matični zavod.
            </p>
            <img className="img-responsive img-thumbnail" src={image_5} alt="05Prosta_mesta" />

            <h3 id="prostaMestaStanjeKapacitet">Stanje kapacitet</h3>
            <p>
                V levem delu zaslonske maske v polje <b>Število oddelkov</b> vnesemo število oddelkov, v polje <b>Število otrok</b> pa število otrok v teh oddelkih. V polju <b>Čakalna doba</b> s pomočjo spustnega seznama izberemo čakalno dobo na prosto mesto in vpišemo Število otrok na čakalnem seznamu. Podatke shranimo s klikom na gumb <b>Shrani</b>. V primeru, da je število otrok v oddelku večje kot je dovoljeno število otrok po normativih in standardih, se na vrhu strani in ob vnosnem polju opozorilo, shranjevanje pa ne bo izvedeno.
            </p>
            <img className="img-responsive img-thumbnail" src={image_6} alt="06Prosta_mesta_napaka" />
            <p>
                Ob uspešnem shranjevanju, se od zgornjim menije prikaže obvestilo.
            </p>
            <img className="img-responsive img-thumbnail" src={image_7} alt="07Prosta_mesta_shranjeno" />
            <p>
                Po kliku na gumb shrani, se bo datum shranjevanja zapisal desno zgoraj v vrstico ob navedbi <b>Zadnja sprememba</b>.
            </p>
            <img className="img-responsive img-thumbnail" src={image_8} alt="08Zadnja_sprememba" />
            <p>
                Po Pravilniku o posredovanju podatkov o stanju kapacitet v vrtcih je potrebno ažurno vnašanje podatkov o spremembah, v kolikor pa sprememb ni je potrebno pravilnost podatkov potrditi, kar storimo s klikom na gumb <b>Shrani</b>.
            </p>

            <h3 id="prostaMestaFleksiNormativ">Fleksibilni normativ</h3>
            <p>
                V desnem delu zaslonske maske vnašamo podatke o fleksibilnih normativih. Podatke vnašamo le za tiste oddelke, ki imajo upoštevan fleksibilni normativ. Pri vnosu števila otrok za posamezno vrsto oddelkov seštejemo število otrok v vseh oddelkih, ki imajo fleksibilni normativ.
            </p>
            <p>
                Kadar je v oddelek vključen otrok s posebnimi potrebami in je zato število otrok v oddelku znižano, štejemo ta oddelek kot fleksibilni, če je število otrok znižano od fleksibilnega normativa (povečanega za 1 oz. 2 otroka). Pri številu otrok napišete dejansko število otrok v oddelku. Primer: v homogenem oddelku ste zaradi vključenega otroka s posebnimi potrebami morali najvišji normativ znižati za dva otroka. Ker ste upoštevali fleksibilni normativ (14 otrok), bo v oddelku 12 otrok. Ta oddelek in otroke boste šteli pri oddelkih s fleksibilnim normativom, povečanim za 2 otroka, v stolpcu število otrok pa boste za ta oddelek upoštevali 12 otrok - dejansko število.
            </p>
            <p>
                Kadar se zaradi manjše kvadrature zniža najvišje možno število otrok v oddelku,  fleksibilnega normativa ne smete uporabljati.
            </p>

            <h3 id="prostaMestaStanjeNaDan">Stanje kapacitet na dan</h3>
            <p>
                Nad tabelo v zgodnjem desnem delu maske se nahaja polje Stanje kapacitet na dan. Ob vnosu ali izbiri datuma in kliku na gumb Shrani, se bodo prikazali podatki o stanju kapacitet na izbrani datum.
            </p>
            <img className="img-responsive img-thumbnail" src={image_9} alt="09Prosta_mesta_na_dan" />

            <h3 id="prostaMestaStanjeNaDan">Enota z oddelki</h3>
            <p>
                Posamezna enota ima lahko oddelke ali ne. V kolikor se zgodi, da enota nima oddelkov, v vrstici <b>Enota z oddelki: Da</b> zamenjamo na <b>Ne</b>. Aplikacija nas bo pri tem opozorila da ne bomo mogli vnašati podatkov o prostih mestih. Po potrditvi bo prikazan zbirnik.
            </p>
            <img className="img-responsive img-thumbnail" src={image_10} alt="10Enota_z_oddelki" />
            <p>
                V kolikor kasneje enota odpre oddelke, zamenjamo podatek Enota z oddelki: Ne, na Da in bomo lahko vpisovali podatke o kapaciteti in prostih mestih.
            </p>

            {/* <!-- CENIK --> */}
            <div className="page-header">
                <h1 id="Cenik">Cenik</h1>
            </div>
            <p>
                Povezava <b>Cenik</b> je na voljo matičnim vrtcem, vrtcem pri OŠ in enotam, ki imajo v aplikaciji EVIZ in VIP označeno polje enota ima svoj lasten cenik.
            </p>
            <img className="img-responsive img-thumbnail" src={image_11} alt="11Cenik" />
            <p>
                V tabelo vpisujemo cene oskrbnin za različne programe in datume veljavnosti. Kot datum lahko izberemo le 1. v mesecu. Ceno lahko spremenimo tudi za prejšnji mesec a le do 20. v trenutnem mesecu, lahko pa novo ceno nastavimo za naslednji mesec. To storimo tako, da desno nad tabelo izberemo mesec in kliknemo na gumb <b>Prikaži</b>, ceno spremenimo ali vnesemo, ustrezno popravimo datum <b>Veljavno od</b> ter kliknemo gumb <b>Shrani</b>. Cene programov lahko pregledujemo tudi za nazaj, a spreminjati jih ne moremo. V kolikor ceno vnašamo prvič, lahko v polje <b>Veljavno od</b> vnesemo kateri koli datum.
            </p>
            <p>
                Na dnu tabele vnašamo <b>Ceno neporabljenih živil na dan odsotnosti</b>. V kolikor ta vrednost presega 2,5EUR vas bo program na to opozoril.
            </p>
            <img className="img-responsive img-thumbnail" src={image_12} alt="12Prehrana_preseganje" />

            {/* <!-- ZBIRNIK --> */}
            <div className="page-header">
                <h1 id="Zbirnik">Zbirnik</h1>
            </div>
            <p>
                Meni Zbirnik je na voljo na matičnih vrtcih. Tabela prikazuje skupek podatkov o Številu oddelkov, Številu otrok in Številu prostih mest za vse enote izbranega vrtca.
            </p>
            <img className="img-responsive img-thumbnail" src={image_13} alt="13Zbirnik" />

            {/* <!-- VSEBINSKA IN TEHNIČNA PODPORA --> */}
            <div className="page-header">
                <h1 id="tehnicnaPodpora">Vsebinska in tehnična podpora</h1>
            </div>
            <p>
                Vsa vsebinska in tehnična vprašanja lahko oddate na portalu ministrstva s klikom na gumb <b>Podpora</b> pri sami aplikaciji.
            </p>
            <img className="img-responsive img-thumbnail" src={image_14} alt="14Podpora" />
            <p>
                Za tehnično podporo smo na voljo tudi na e-poštnem naslovu <a href="mailto:info@izstop.si">info@izstop.si</a>.
            </p>
        </div>
    )
}

export default Help