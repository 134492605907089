import create from 'zustand'

export const useInstStore = create((set) => ({
    institution: null,
    hasPricelist: false,
    hasJoined: false,
    setInstitution: (id) => set({ institution: id }),
    setHasPricelist: (value) => set({ hasPricelist: value }),
    setHasJoined: (value) => set({ hasJoined: value }),
}))

export const useNotificationStore = create((set, get) => ({
    notification: null,
    setNotification: (value) => set({ notification: value }),
}))