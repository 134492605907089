import React, { useState, useRef } from 'react'
import Filter from './Filter'

const Filters = ({filters, handleFilter, getData}) => {
    const [showData, setShowData] = useState(true)
    const title = useRef(null)
    
    const expand = () => {
        title.current.classList.toggle("opened")
        setShowData(!showData)
    }
    
    if (filters !== null) {
        return (
            <div className="row">
                <div className="col-sm-12 col-lg-8">
                    <div id="filter-box" className="panel panel-primary">
                        <div ref={title} className="panel-heading"  onClick={() => expand()}>
                            <span className="caret caretSmall"></span>
                            <span className="control-label">Filter</span>
                        </div>
                        {showData && 
                            <div className="panel-body">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <Filter filter={filters.region} handleFilter={handleFilter} />
                                        <Filter filter={filters.legalStatus} handleFilter={handleFilter} />
                                    </div>
                                    <div className="row">
                                        <Filter filter={filters.municipality} handleFilter={handleFilter} />
                                        <Filter filter={filters.program} handleFilter={handleFilter} />
                                    </div>
                                    <div className="row">
                                        <Filter filter={filters.title} handleFilter={handleFilter} getData={getData} />
                                        <Filter filter={filters.status} handleFilter={handleFilter} />
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-sm-12 col-md-6">
                                            <div className="col-sm-8 col-sm-offset-4">
                                                <button className="btn btn-primary" id="filter-prikazi" onClick={() => getData(false)}>Prikaži</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return "LOADING FILTER"
    }
}

export default Filters