import React, {useState, useMemo, useRef, Fragment} from 'react'
import axios from 'axios'
import moment from 'moment'

import env from '../../env/src_config'
import { number_format, price_format } from '../../izUtils'
import ProgressBar from '../progressBar/ProgressBar'
import { ErrorResponse } from '../errorResponse/ErrorResponse'
import { isNull } from '../../izUtils'

const Table = ({occupation, pricelist, options}) => {
    const [showData, setShowData] = useState(true)
    const title = useRef(null)
    const showExport = useRef(null)


    const expand = (type) => {
        if (type === 'title') {
            title.current.classList.toggle("opened")
            setShowData(!showData)
        } else {
            showExport.current.classList.toggle("open")
        }
    }

    const fileExport = (type) => {
        let downloadType = 'application/pdf'
        if(type === 'xlsx') {
            downloadType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        } else if (type === 'docx') {
            downloadType = 'application/docx'
        }

        const payload = {
            type: type,
            validOn: moment(new Date()).format(),
            filter: {}
        }

        const keys = ["region", "legalStatus", "municipality", "program", "title", "status"];
        const toInt = [/*"region", "municipality", "program"*/];
        const toCheck = ["status"]

        const filtersLS =  JSON.parse(localStorage.getItem("NAJAVAPSV.institution-filter"));
        console.log("filtersLS:",filtersLS)
        if (!isNull(filtersLS)) {
            keys.forEach(key => {
                if (filtersLS[key].value !== null && filtersLS[key].value.length !== 0) {
                    if (toInt.indexOf(key) !== -1) {
                        payload.filter[key] = parseInt(filtersLS[key].value) 
                    } else if (toCheck.indexOf(key) !== -1) {
                        payload.filter[key] = filtersLS[key].value ? 1 : 0
                    } else {
                        payload.filter[key] = filtersLS[key].value;
                    }
                }
            })
        }

        axios.post(env.api + 'admin/enrollment/export', payload, options).then(response => {
            if (response.data.generatedPath) {
                axios({url: env.api + 'admin/download/' + response.data.generatedPath, method: 'GET', headers: options.headers, responseType: 'blob'})
                    .then(res => {
                        if (res.status === 200) {
                            console.log(res)
                            let file = new Blob([res.data], {type: downloadType});
                            let a = document.createElement("a");
                            let url = URL.createObjectURL(file);
                            a.href = url;
                            a.download = response.data.generatedPath;
                            document.body.appendChild(a);
                            a.click();
                            setTimeout(function() {
                                document.body.removeChild(a);
                                window.URL.revokeObjectURL(url);
                            }, 0);
                        }
                    })
                    .catch(error => {
                        ErrorResponse(error)
                    })  
            }
        })
        .catch(error => {
            ErrorResponse(error)
        })
    }

    let columns = useMemo(() => {
        if (occupation !== null) {
            let col = {};
            occupation.values.forEach(row => {
                row.forEach(item => {
                    if (!col[item.column]) col[item.column] = [];
                    col[item.column][item.rowID] = item;
                })
            })

            return col
        } else return {}
    }, [occupation])

    // Count the columns
    let together = useMemo(() => {
        let keys = ["ST_ODDELKOV", "ST_OTROK", "ST_PROSTIH_MEST", "ST_OTROK_CAKA", "FLEKS_EN_ODD", "FLEKS_EN_ST_OTROK", "FLEKS_DVA_ODD", "FLEKS_DVA_ST_OTROK"]
        let togetherColumn = []; 

        keys.forEach((row, index) => {
            if (columns[row]) {
                let count = 0;
                columns[row].forEach(item => {
                    if (!item.hidden) count += item.value;
                })
                togetherColumn.push(<td key={index + '_together' } className={row === 'FLEKS_DVA_ST_OTROK' ? 'strongBorderR' : ''} >{number_format(count)}</td>);
            }
        });

        return (
            <tr>
                <td>Skupaj</td>
                <td className="strongBorderR"></td>
                {togetherColumn}
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        )
    }, [columns])

    return (
        <div style={{ position: 'relative' }}>
            <h2 id="zavodi-summary-header" className="expandableHeader" onClick={() => expand('title')} ref={title}><span className="caret caretBig"></span>Povzetek</h2>
            {pricelist === null ?
                <ProgressBar />
                :
                <div id="zavodi-summary" className="contentBox">
                    {showData && 
                        <Fragment>
                            <div className='row pull-right'>
                                <div className='col-sm-12'>
                                    <div className='prevTopRight' ref={showExport}>
                                        <button className='btn btn-default' style={{marginRight: '-10px'}} onClick={() => fileExport('xlsx')} >Excel</button>
                                        <button className='btn btn-default dropdown-toggle' onClick={() => expand('export')}>
                                            <span className='caret'></span>
                                        </button>
                                        <ul className='dropdown-menu'>
                                            <li><button onClick={() => fileExport('docx')}>Word</button></li>
                                            <li><button onClick={() => fileExport('pdf')}>Pdf</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <table className='table table-bordered table-condensed'>
                                        <thead>
                                            <tr>
                                                <th className="strongBorderR" colSpan="10">ZASEDENOST IN PROSTA MESTA</th>
                                                <th colSpan="4">CENIK&nbsp;-&nbsp;{new Date().toLocaleString('sl-SI', { month: 'long' })}&nbsp;{new Date().getFullYear()}</th>
                                            </tr>
                                            <tr>
                                                <th className="strongBorderB" rowSpan="3">Starostno obdobje</th>
                                                <th className="strongBorderB strongBorderR" rowSpan="3">Oznaka oddelka</th>
                                                <th className="strongBorderB" rowSpan="3">Število oddelkov</th>
                                                <th className="strongBorderB" rowSpan="3">Število otrok</th>
                                                <th className="strongBorderB" rowSpan="3">Število prostih mest</th>
                                                <th className="strongBorderB" rowSpan="3">Število otrok na čakalnem seznamu</th>
                                                <th className="strongBorderR" colSpan="4">Fleksi normativ</th>
                                                <th className="strongBorderB" rowSpan="3"></th>
                                                <th className="strongBorderB" rowSpan="3">Dnevni program</th>
                                                <th className="strongBorderB" rowSpan="3">Poldnevni program</th>
                                                <th className="strongBorderB" rowSpan="3">Krajši program</th>
                                            </tr>
                                            <tr>
                                                <th colSpan="2">en otrok več</th>
                                                <th className="strongBorderR" colSpan="2">dva otroka več</th>
                                            </tr>
                                            <tr>
                                                <th className="strongBorderB">število oddelkov</th>
                                                <th className="strongBorderB">število otrok</th>
                                                <th className="strongBorderB">število oddelkov</th>
                                                <th className="strongBorderB strongBorderR">število otrok</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="orgGroup1">
                                                <td rowSpan="3">1. starostno obdobje</td>
                                                <td className="strongBorderR">1-2</td>
                                                <td>{number_format(columns['ST_ODDELKOV'][0].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][0].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][0].value)}</td>
                                                <td rowSpan="3">{number_format(columns['ST_OTROK_CAKA'][0].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ODD'][0].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ST_OTROK'][0].value)}</td>
                                                <td>{number_format(columns['FLEKS_DVA_ODD'][0].value)}</td>
                                                <td className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][0].value)}</td>
                                                <td rowSpan="3">Cena</td>
                                                <td rowSpan="3">{price_format(pricelist.values[0][0].value)} €</td>
                                                <td rowSpan="3">{price_format(pricelist.values[0][1].value)} €</td>
                                                <td rowSpan="3">{price_format(pricelist.values[0][2].value)} €</td>
                                            </tr>
                                            <tr className="orgGroup1">
                                                <td className="strongBorderR">2-3</td>
                                                <td>{number_format(columns['ST_ODDELKOV'][1].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][1].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][1].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ODD'][1].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ST_OTROK'][1].value)}</td>
                                                <td>{number_format(columns['FLEKS_DVA_ODD'][1].value)}</td>
                                                <td className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][1].value)}</td>
                                            </tr>
                                            <tr className="orgGroup1">
                                                <td className="strongBorderR">1-3</td>
                                                <td>{number_format(columns['ST_ODDELKOV'][2].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][2].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][2].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ODD'][2].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ST_OTROK'][2].value)}</td>
                                                <td>{number_format(columns['FLEKS_DVA_ODD'][2].value)}</td>
                                                <td className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][2].value)}</td>
                                            </tr>
                                            <tr className="orgGroup2">
                                                <td rowSpan="4">2. starostno obdobje</td>
                                                <td className="strongBorderR">3-4</td>
                                                <td>{number_format(columns['ST_ODDELKOV'][3].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][3].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][3].value)}</td>
                                                <td rowSpan="4">{number_format(columns['ST_OTROK_CAKA'][3].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ODD'][3].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ST_OTROK'][3].value)}</td>
                                                <td>{number_format(columns['FLEKS_DVA_ODD'][3].value)}</td>
                                                <td className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][3].value)}</td>
                                                <td rowSpan="4">Cena</td>
                                                <td rowSpan="4">{price_format(pricelist.values[1][0].value)} €</td>
                                                <td rowSpan="4">{price_format(pricelist.values[1][1].value)} €</td>
                                                <td rowSpan="4">{price_format(pricelist.values[1][2].value)} €</td>
                                            </tr>
                                            <tr className="orgGroup2">
                                                <td className="strongBorderR">4-5</td>
                                                <td>{number_format(columns['ST_ODDELKOV'][4].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][4].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][4].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ODD'][4].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ST_OTROK'][4].value)}</td>
                                                <td>{number_format(columns['FLEKS_DVA_ODD'][4].value)}</td>
                                                <td className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][4].value)}</td>
                                            </tr>
                                            <tr className="orgGroup2">
                                                <td className="strongBorderR">5-6</td>
                                                <td>{number_format(columns['ST_ODDELKOV'][5].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][5].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][5].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ODD'][5].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ST_OTROK'][5].value)}</td>
                                                <td>{number_format(columns['FLEKS_DVA_ODD'][5].value)}</td>
                                                <td className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][5].value)}</td>
                                            </tr>
                                            <tr className="orgGroup2">
                                                <td className="strongBorderR">3-6</td>
                                                <td>{number_format(columns['ST_ODDELKOV'][6].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][6].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][6].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ODD'][6].value)}</td>
                                                <td>{number_format(columns['FLEKS_EN_ST_OTROK'][6].value)}</td>
                                                <td>{number_format(columns['FLEKS_DVA_ODD'][6].value)}</td>
                                                <td className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][6].value)}</td>
                                            </tr>
                                            <tr className="orgGroup7">
                                                <td colSpan="2" className="strongBorderR">Otroci 3-4 let</td>
                                                <td colSpan="8" className="strongBorderR"></td>
                                                <td>Cena</td>
                                                <td>{price_format(pricelist.values[2][0].value)} €</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className="orgGroup3">
                                                <td rowSpan="2">Kombinirani oddelki</td>
                                                <td className="strongBorderR">Otroci 1. star. obdobja</td>
                                                <td rowSpan="2">{number_format(columns['ST_ODDELKOV'][7].value)}</td>
                                                <td rowSpan="2">{number_format(columns['ST_OTROK'][7].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][7].value)}</td>
                                                <td rowSpan="2">{number_format(columns['ST_OTROK_CAKA'][7].value)}</td>
                                                <td rowSpan="2">{number_format(columns['FLEKS_EN_ODD'][7].value)}</td>
                                                <td rowSpan="2">{number_format(columns['FLEKS_EN_ST_OTROK'][7].value)}</td>
                                                <td rowSpan="2">{number_format(columns['FLEKS_DVA_ODD'][7].value)}</td>
                                                <td rowSpan="2" className="strongBorderR">{number_format(columns['FLEKS_DVA_ST_OTROK'][7].value)}</td>
                                                <td rowSpan="2">Cena</td>
                                                <td rowSpan="2">{price_format(pricelist.values[3][0].value)} €</td>
                                                <td rowSpan="2">{price_format(pricelist.values[3][1].value)} €</td>
                                                <td rowSpan="2">{price_format(pricelist.values[3][2].value)} €</td>
                                            </tr>
                                            <tr className="orgGroup3">
                                                <td className="strongBorderR">Otroci 2. star. obdobja</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][8].value)}</td>
                                            </tr>
                                            <tr className="orgGroup4">
                                                <td>Razvojni oddelki</td>
                                                <td className="strongBorderR"></td>
                                                <td>{number_format(columns['ST_ODDELKOV'][9].value)}</td>
                                                <td>{number_format(columns['ST_OTROK'][9].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][9].value)}</td>
                                                <td>{number_format(columns['ST_OTROK_CAKA'][9].value)}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="strongBorderR"></td>
                                                <td>Cena</td>
                                                <td>{price_format(pricelist.values[4][0].value)} €</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className="orgGroup5">
                                                <td rowSpan="2">Vzgojno-varstvena družina</td>
                                                <td className="strongBorderR">1-3</td>
                                                <td rowSpan="2">{number_format(columns['ST_ODDELKOV'][10].value)}</td>
                                                <td rowSpan="2">{number_format(columns['ST_OTROK'][10].value)}</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][10].value)}</td>
                                                <td rowSpan="2">{number_format(columns['ST_OTROK_CAKA'][10].value)}</td>
                                                <td rowSpan="2"></td>
                                                <td rowSpan="2"></td>
                                                <td rowSpan="2"></td>
                                                <td rowSpan="2" className="strongBorderR"></td>
                                                <td rowSpan="2">Cena</td>
                                                <td rowSpan="2">{price_format(pricelist.values[5][0].value)} €</td>
                                                <td rowSpan="2"></td>
                                                <td rowSpan="2"></td>
                                            </tr>
                                            <tr className="orgGroup5">
                                                <td className="strongBorderR">3-6</td>
                                                <td>{number_format(columns['ST_PROSTIH_MEST'][11].value)}</td>
                                            </tr>
                                            <tr className="orgGroup6">
                                                <td colSpan="2" className="strongBorderR">Cena neporabljenih živil na dan odsotnosti</td>
                                                <td colSpan="8" className="strongBorderR"></td>
                                                <td>Cena</td>
                                                <td>{price_format(pricelist.values[6][0].value)} €</td>
                                                <td>{price_format(pricelist.values[6][1].value)} €</td>
                                                <td>{price_format(pricelist.values[6][2].value)} €</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            {together}
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
            }
        </div>
    )
}

export default Table