import React, { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import env from '../../env/src_config'
import ProgressBar from '../../hoc/progressBar/ProgressBar'
import { ErrorResponse } from '../../hoc/errorResponse/ErrorResponse'
import { useInstStore } from '../../store'
import JoinedTable from './JoinedTable'

const JoinedOccupation = ({options}) => {
    const { id } = useParams()
    const { setInstitution, setHasPricelist, setHasJoined } = useInstStore((state) => ({
        setInstitution: state.setInstitution,
        setHasPricelist: state.setHasPricelist,
        setHasJoined: state.setHasJoined
    }))

    const [data, setData] = useState(null);

    useEffect(() => {
        axios.post(env.api + 'admin/enrollment', {"id": parseInt(id)}, options)
        .then(response => {
            setInstitution(id)
            setHasPricelist(response.data.hasOwnPricelist)
            setHasJoined(response.data.topInstitution === parseInt(id))

            setData(response.data);
        })
        .catch(error => {
            ErrorResponse(error)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, options])

    
    if (data !== null) {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>{data.name}&nbsp;({data.prs + ' ' + data.mss})</h3>
                                <div className="contactInfo">{data.address.string}</div>
                                <div className="contactInfo">Telefonska številka: {data.telephone.length !== 0 ? data.telephone : "/" }, E-mail: {data.email.length !== 0 ? data.email : "/" }</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div id="zbirnik">
                            <JoinedTable data={data} type="joinedOccupation" />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    } else {
        return <ProgressBar />
    }
}

export default JoinedOccupation