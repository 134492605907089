import React from 'react'

import Input from '../../hoc/form/Input'
import DateInput from '../../hoc/form/DateInput'

const PriceTable = ({pricelist, handleChange, handleDate, disabled, validOn}) => {
    const inputParams = { handleChange, disabled, price: true }
    const dateParams = { handleChange: handleDate, disabled, validOn, onlyFirstDay: true }

    return (
        <table id="cenikTbl" className='table table-bordered table-condensed'>
            <thead>
                <tr>
                    <th colSpan="2"></th>
                    <th>Dnevni program</th>
                    <th>Poldnevni program</th>
                    <th>Krajši program</th>
                </tr>
            </thead>
            <tbody>
                {/* 1. starostno obdobje */}
                <tr className="orgGroup1">
                    <td rowSpan="2" className="strongBorderB"><strong>1. starostno obdobje</strong></td>
                    <td>Cena</td>
                    <td><Input data={pricelist['01'][0]} {...inputParams}/></td>
                    <td><Input data={pricelist['02'][0]} {...inputParams}/></td>
                    <td><Input data={pricelist['03'][0]} {...inputParams}/></td>
                </tr>
            <tr className="orgGroup1">
                    <td className="strongBorderB">Veljavno od</td>
                    <td className="strongBorderB"><DateInput data={pricelist['01'][0]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['02'][0]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['03'][0]} {...dateParams}/></td>
                </tr>
                {/* 2. starostno obdobje */}
                <tr className="orgGroup2">
                    <td rowSpan="2" className="strongBorderB"><strong>2. starostno obdobje</strong></td>
                    <td>Cena</td>
                    <td><Input data={pricelist['01'][1]} {...inputParams}/></td>
                    <td><Input data={pricelist['02'][1]} {...inputParams}/></td>
                    <td><Input data={pricelist['03'][1]} {...inputParams}/></td>
                </tr>
            <tr className="orgGroup2">
                    <td className="strongBorderB">Veljavno od</td>
                    <td className="strongBorderB"><DateInput data={pricelist['01'][1]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['02'][1]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['03'][1]} {...dateParams}/></td>
                </tr>

                {/* Otroci 3-4 let  */}
                <tr className="orgGroup7">
                    <td rowSpan="2" className="strongBorderB"><strong>Otroci 3-4 let</strong></td>
                    <td>Cena</td>
                    <td><Input data={pricelist['01'][2]} {...inputParams}/></td>
                    <td></td>
                    <td></td>
                </tr>
            <tr className="orgGroup7">
                    <td className="strongBorderB">Veljavno od</td>
                    <td className="strongBorderB"><DateInput data={pricelist['01'][2]} {...dateParams}/></td>
                    <td className="strongBorderB"></td>
                    <td className="strongBorderB"></td>
                </tr>

                {/* Kombinirani oddelki */}
                <tr className="orgGroup3">
                    <td rowSpan="2" className="strongBorderB"><strong>Kombinirani oddelki</strong></td>
                    <td>Cena</td>
                    <td><Input data={pricelist['01'][3]} {...inputParams}/></td>
                    <td><Input data={pricelist['02'][3]} {...inputParams}/></td>
                    <td><Input data={pricelist['03'][3]} {...inputParams}/></td>
                </tr>
                <tr className="orgGroup3">
                    <td className="strongBorderB">Veljavno od</td>
                    <td className="strongBorderB"><DateInput data={pricelist['01'][3]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['02'][3]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['03'][3]} {...dateParams}/></td>
                </tr>

                {/* Razvojni oddelek */}
                <tr className="orgGroup4">
                    <td rowSpan="2" className="strongBorderB"><strong>Razvojni oddelek</strong></td>
                    <td>Cena</td>
                    <td><Input data={pricelist['01'][4]} {...inputParams}/></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className="orgGroup4">
                    <td className="strongBorderB">Veljavno od</td>
                    <td className="strongBorderB"><DateInput data={pricelist['01'][4]} {...dateParams}/></td>
                    <td className="strongBorderB"></td>
                    <td className="strongBorderB"></td>
                </tr>

                {/* Vzgojno-varstvena družina */}
                <tr className="orgGroup5">
                    <td rowSpan="2" className="strongBorderB"><strong>Vzgojno-varstvena družina</strong></td>
                    <td>Cena</td>
                    <td><Input data={pricelist['01'][5]} {...inputParams}/></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className="orgGroup5">
                    <td className="strongBorderB">Veljavno od</td>
                    <td className="strongBorderB"><DateInput data={pricelist['01'][5]} {...dateParams}/></td>
                    <td className="strongBorderB"></td>
                    <td className="strongBorderB"></td>
                </tr>

                {/* Cena neporabljenih živil na dan odsotnosti */}
                <tr className="orgGroup6">
                    <td rowSpan="2" className="strongBorderB"><strong>Cena neporabljenih živil na dan odsotnosti</strong></td>
                    <td>Cena</td>
                    <td><Input data={pricelist['01'][6]} {...inputParams}/></td>
                    <td><Input data={pricelist['02'][6]} {...inputParams}/></td>
                    <td><Input data={pricelist['03'][6]} {...inputParams}/></td>
                </tr>
                <tr className="orgGroup6">
                    <td className="strongBorderB">Veljavno od</td>
                    <td className="strongBorderB"><DateInput data={pricelist['01'][6]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['02'][6]} {...dateParams}/></td>
                    <td className="strongBorderB"><DateInput data={pricelist['03'][6]} {...dateParams}/></td>
                </tr>
            </tbody>
        </table>
    )
}

export default PriceTable