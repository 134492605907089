import { Fragment, Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Swal from "sweetalert2";
import axios from "axios";

import Header from './hoc/header/Header'
import Institutions from "./components/Institutions";
import Available from "./components/available/Available";
import PriceList from "./components/pricelist/PriceList";
import JoinedOccupation from "./components/joinedOccupation/JoinedOccupation";
import Help from "./components/Help";

import env from "./env/src_config";
import { findGetParameter } from "./izUtils";

import './assets/css/SandstoneTheme.css'
import './assets/css/themes.css'
import './assets/css/psv_css.css'
import './assets/css/custom.css'
import "react-datepicker/dist/react-datepicker.css"

function App() {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    useEffect(() => {
        let key = findGetParameter("key");
        if (key) {
            axios.get(env.api + 'login', { params: { key } }).then(response => {
                console.log('response: ')
                console.log(response)

                setCookie('token', response.data, { path: '/' });
            }, (error) => {
                if (error.response === 'Invalid Key') {
                    Swal.fire({
                        title: "Napaka!",
                        html: 'Žeton ni veljaven',
                        icon: "error",
                        confirmButtonText: "V redu"
                    });
                } else alert(error.response);
            })
        } else {
            const responseToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBY2Nlc3NlcyI6WzI3MV0sIkFsbG93ZWRJbnN0aXR1dGlvbnMiOltdLCJBbGxvd2VkR3JvdXBUeXBlcyI6W10sIkFsbG93ZWRDYXRlZ29yaWVzIjpbXSwiQXV0aG9yaXplZCI6dHJ1ZSwiRU1TTyI6IjIzMDU5ODQ1MDAxMDkiLCJOYW1lIjoiR2VuZXJpcmFuYSBwcmF2aWNhIiwiU3VybmFtZSI6IiIsIkVtYWlsIjoiTmkgZW5hc2xvdmEiLCJPcmdhbml6YXRpb24iOiIiLCJJbnN0aXR1dGlvbiI6MjI0LCJleHAiOjE3ODY5MzA1OTQsImlzRGV2Ijp0cnVlLCJpYXQiOjE2Njc4MzAxODZ9.jwJOPdGllRn1tN1JdLHWQ0yjv1VQh-owuaKL2CbAxiE';
            setCookie('token', responseToken, { path: '/' });
        }

        return () => removeCookie('token')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (cookies.token !== undefined && cookies.token !== null) {
        console.log(env)
        const routePrefix = env.spa_home;

        const options = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + cookies.token,
            }
        }
        return (
            <Fragment>
                <div id="main-wrap" className="container">
                    <Header />
                    <div id="main-content" className="container">
                        <Routes>
                            <Route path={routePrefix + ""} element={ <Institutions options={options} /> } />
                            <Route path={routePrefix + "prostaMesta/:id"} element={ <Suspense fallback={"Loading"}><Available options={options} /></Suspense> } />
                            <Route path={routePrefix + "cenik/:id"} element={ <Suspense fallback={"Loading"}><PriceList options={options} /></Suspense> } />
                            <Route path={routePrefix + "zbirnik/:id"} element={ <Suspense fallback={"Loading"}>{<JoinedOccupation options={options} />}</Suspense> } />
                            <Route path={routePrefix + "pomoc"} element={ <Help /> } />
    
                            <Route path="*" element={<Navigate to={routePrefix} />} />
                        </Routes>
                    </div>
                </div>
                <div id="footer">
                    <div className="container">
                        <p className="text-muted credit">
                            <a href='https://izstop.si/' target="_blank" rel="noreferrer">Izstop d.o.o.</a>
                        </p>
                    </div>
                </div>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div id="main-wrap" className="container">
                    <div className="navbar navbar-inverse navbar-fixed-top">
                        <div className="container">
                            <div className="navbar-header">
                                <a className="navbar-brand" href="/NajavaPSV/">Prosta mesta PŠV</a>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <h2 className="text-danger">Prišlo je do napake.</h2>
                        <h3>Za ogled strani je potrebna prijava!</h3>
                    </div>

                </div>
            </Fragment>
        )
    }

}

export default App;
