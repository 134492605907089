import React from 'react'

import Input from '../../hoc/form/Input'
import Select from '../../hoc/form/Select'

const AvailableTable = ({occupation, handleChange}) => {
    return (
        <table id="prostaMestaTbl" className="table table-bordered table-condensed">
            <thead>
                <tr>
                    <th className="strongBorderB" rowSpan="3">Starostno obdobje</th>
                    <th className="strongBorderB strongBorderR" rowSpan="3">Oznaka oddelka</th>
                    <th className="strongBorderB" rowSpan="3">Število oddelkov</th>
                    <th className="strongBorderB" rowSpan="3">Število otrok</th>
                    <th className="strongBorderB" rowSpan="3">Število prostih mest</th>
                    <th className="strongBorderB" rowSpan="3">Čakalna doba</th>
                    <th colSpan="4" className="text-center">Fleksi normativ</th>
                </tr>
                <tr>
                    <th colSpan="2" className="text-center">En otrok več</th>
                    <th colSpan="2" className="text-center">Dva otroka več</th>
                </tr>
                <tr>
                    <th className="strongBorderB">Število oddelkov</th>
                    <th className="strongBorderB">Število otrok</th>
                    <th className="strongBorderB">Število oddelkov</th>
                    <th className="strongBorderB">Število otrok</th>
                </tr>
            </thead>
            <tbody>
                {/* PRVO STAROSTNO OBDOBJE */}
                <tr className="section orgGroup1">
                    <td className="strongBorderB" rowSpan="3"><strong>1. starostno obdobje</strong></td>
                    <td className="strongBorderR">1-2</td>
                    <td><Input data={occupation['ST_ODDELKOV'][0]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_OTROK'][0]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_PROSTIH_MEST'][0]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="3">
                        <Select data={occupation['PREDVIDENA_DOBA_REF'][0]} handleChange={handleChange}/>
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon input-sm" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Število otrok na čakalnem seznamu">
                                    Število otrok:
                                </span>
                                <Input data={occupation['ST_OTROK_CAKA'][0]} handleChange={handleChange} />
                            </div>
                            <span className="field-validation-valid text-danger" data-valmsg-for="StCakalniSeznam[1StarostnoObdobje]" data-valmsg-replace="true"></span>
                        </div>
                    </td>
                    <td><Input data={occupation['FLEKS_EN_ODD'][0]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ST_OTROK'][0]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ODD'][0]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ST_OTROK'][0]} handleChange={handleChange} /></td>
                </tr>
                <tr className="orgGroup1">
                    <td className="strongBorderR">2-3</td>
                    <td><Input data={occupation['ST_ODDELKOV'][1]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_OTROK'][1]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_PROSTIH_MEST'][1]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ODD'][1]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ST_OTROK'][1]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ODD'][1]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ST_OTROK'][1]} handleChange={handleChange} /></td>
                </tr>
                <tr className="orgGroup1">
                    <td className="strongBorderB strongBorderR">1-3</td>
                    <td className="strongBorderB"><Input data={occupation['ST_ODDELKOV'][2]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['ST_OTROK'][2]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['ST_PROSTIH_MEST'][2]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_EN_ODD'][2]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_EN_ST_OTROK'][2]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_DVA_ODD'][2]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_DVA_ST_OTROK'][2]} handleChange={handleChange} /></td>
                </tr>

                {/* DRUGO STAROSTNO OBDOBJE */}
                <tr className="section orgGroup2">
                    <td className="strongBorderB" rowSpan="4"><strong>2. starostno obdobje</strong></td>
                    <td className="strongBorderR">3-4</td>
                    <td><Input data={occupation['ST_ODDELKOV'][3]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_OTROK'][3]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_PROSTIH_MEST'][3]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="4">
                        <Select data={occupation['PREDVIDENA_DOBA_REF'][3]} handleChange={handleChange}/>
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon input-sm" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Število otrok na čakalnem seznamu">
                                    Število otrok:
                                </span>
                                <Input data={occupation['ST_OTROK_CAKA'][3]} handleChange={handleChange} />
                            </div>
                            <span className="field-validation-valid text-danger" data-valmsg-for="StCakalniSeznam[2StarostnoObdobje]" data-valmsg-replace="true"></span>
                        </div>
                    </td>
                    <td><Input data={occupation['FLEKS_EN_ODD'][3]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ST_OTROK'][3]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ODD'][3]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ST_OTROK'][3]} handleChange={handleChange} /></td>
                </tr>
                <tr className="orgGroup2">
                    <td className="strongBorderR">4-5</td>
                    <td><Input data={occupation['ST_ODDELKOV'][4]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_OTROK'][4]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_PROSTIH_MEST'][4]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ODD'][4]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ST_OTROK'][4]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ODD'][4]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ST_OTROK'][4]} handleChange={handleChange} /></td>
                </tr>
                <tr className="orgGroup2">
                    <td className="strongBorderR">5-6</td>
                    <td><Input data={occupation['ST_ODDELKOV'][5]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_OTROK'][5]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_PROSTIH_MEST'][5]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ODD'][5]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_EN_ST_OTROK'][5]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ODD'][5]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['FLEKS_DVA_ST_OTROK'][5]} handleChange={handleChange} /></td>
                </tr>
                <tr className="orgGroup2">
                    <td className="strongBorderB strongBorderR">3-6</td>
                    <td className="strongBorderB"><Input data={occupation['ST_ODDELKOV'][6]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['ST_OTROK'][6]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['ST_PROSTIH_MEST'][6]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_EN_ODD'][6]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_EN_ST_OTROK'][6]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_DVA_ODD'][6]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['FLEKS_DVA_ST_OTROK'][6]} handleChange={handleChange} /></td>
                </tr>
                {/* KOMBINIRANI ODDELKI */}
                <tr className="section orgGroup3">
                    <td className="strongBorderB" rowSpan="2"><strong>Kombinirani oddelki</strong></td>
                    <td className="strongBorderR">1. star. obdobje</td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['ST_ODDELKOV'][7]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['ST_OTROK'][7]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_PROSTIH_MEST'][7]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="2">
                        <Select data={occupation['PREDVIDENA_DOBA_REF'][7]} handleChange={handleChange}/>
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon input-sm" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Število otrok na čakalnem seznamu">
                                    Število otrok:
                                </span>
                                <Input data={occupation['ST_OTROK_CAKA'][7]} handleChange={handleChange} />
                            </div>
                            <span className="field-validation-valid text-danger" data-valmsg-for="StCakalniSeznam[KombiniraniOddelki]" data-valmsg-replace="true"></span>
                        </div>
                    </td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['FLEKS_EN_ODD'][7]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['FLEKS_EN_ST_OTROK'][7]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['FLEKS_DVA_ODD'][7]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['FLEKS_DVA_ST_OTROK'][7]} handleChange={handleChange} /></td>
                </tr>
                <tr className="orgGroup3">
                    <td className="strongBorderB strongBorderR">2. star. obdobje</td>
                    <td className="strongBorderB"><Input data={occupation['ST_PROSTIH_MEST'][8]} handleChange={handleChange} /></td>
                </tr>
                {/* RAZVOJNI ODDELKI */}
                <tr className="section orgGroup4">
                    <td className="strongBorderB"><strong>Razvojni oddelki</strong></td>
                    <td className="strongBorderB strongBorderR"></td>
                    <td className="strongBorderB"><Input data={occupation['ST_ODDELKOV'][9]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['ST_OTROK'][9]} handleChange={handleChange} /></td>
                    <td className="strongBorderB"><Input data={occupation['ST_PROSTIH_MEST'][9]} handleChange={handleChange} /></td>
                    <td className="strongBorderB">
                        <Select data={occupation['PREDVIDENA_DOBA_REF'][9]} handleChange={handleChange}/>
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon input-sm" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Število otrok na čakalnem seznamu">
                                    Število otrok:
                                </span>
                                <Input data={occupation['ST_OTROK_CAKA'][9]} handleChange={handleChange} />
                            </div>
                            <span className="field-validation-valid text-danger" data-valmsg-for="StCakalniSeznam[RazvojniOddelki]" data-valmsg-replace="true"></span>
                        </div>
                    </td>
                    <td className="strongBorderB"></td>
                    <td className="strongBorderB"></td>
                    <td className="strongBorderB"></td>
                    <td className="strongBorderB"></td>
                </tr>
                {/* VZGOJNO VARSTVENA DRUŽINA */}
                <tr className="section orgGroup5">
                    <td className="strongBorderB" rowSpan="2"><strong>Vzgojno-varstvena družina</strong></td>
                    <td className="strongBorderR">1-3</td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['ST_ODDELKOV'][10]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="2"><Input data={occupation['ST_OTROK'][10]} handleChange={handleChange} /></td>
                    <td><Input data={occupation['ST_PROSTIH_MEST'][10]} handleChange={handleChange} /></td>
                    <td className="strongBorderB" rowSpan="2">
                        <Select data={occupation['PREDVIDENA_DOBA_REF'][10]} handleChange={handleChange}/>
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon input-sm" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Število otrok na čakalnem seznamu">
                                    Število otrok:
                                </span>
                                <Input data={occupation['ST_OTROK_CAKA'][10]} handleChange={handleChange} />
                            </div>
                            <span className="field-validation-valid text-danger" data-valmsg-for="StCakalniSeznam[VzgojnoVarstvenaDruzina]" data-valmsg-replace="true"></span>
                        </div>
                    </td>
                    <td className="strongBorderB" rowSpan="2"></td>
                    <td className="strongBorderB" rowSpan="2"></td>
                    <td className="strongBorderB" rowSpan="2"></td>
                    <td className="strongBorderB" rowSpan="2"></td>
                </tr>
                <tr className="orgGroup5">
                    <td className="strongBorderB strongBorderR">3-6</td>
                    <td className="strongBorderB"><Input data={occupation['ST_PROSTIH_MEST'][11]} handleChange={handleChange} /></td>
                </tr> 
            </tbody>
        </table>
    )
}

export default AvailableTable