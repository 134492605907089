import React, {useEffect, useState} from 'react'
import axios from 'axios'

import env from '../env/src_config'
import Filters from '../hoc/filters/Filters'
import List from '../hoc/list/List'
import Table from '../hoc/table/Table'
import { ErrorResponse } from '../hoc/errorResponse/ErrorResponse'
import useLocalStorage from '../hoc/hooks/useLocalStorage'
import { isNull } from '../izUtils'

const Institutions = ({options}) => {
    const [filters, setFilters] = useState(null)
    const [list, setList] = useState(null)
    const [occupation, setOccupation] = useState(null)
    const [pricelist, setPricelist] = useState(null)
    const [pagination, setPagination] = useState(null)

    const [filtersLS, setFiltersLS] = useLocalStorage("NAJAVAPSV.institution-filter", null);

    useEffect(() => {
        getData(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    const getData = (firstTime = false) => {
        let payload = {
            filter: {}
        }

        const keys = ["region", "legalStatus", "municipality", "program", "title", "status"];
        const toInt = [/*"region", "municipality", "program"*/];
        const toCheck = ["status"]

        if (firstTime) {
            if (isNull(filtersLS)) {
                payload.filter.status = 1
            } else {
                keys.forEach(key => {
                    if (filtersLS[key].value !== null && filtersLS[key].value.length !== 0) {
                        if (toInt.indexOf(key) !== -1) {
                            payload.filter[key] = parseInt(filtersLS[key].value) 
                        } else if (toCheck.indexOf(key) !== -1) {
                            payload.filter[key] = filtersLS[key].value ? 1 : 0
                        } else {
                            payload.filter[key] = filtersLS[key].value;
                        }
                    }
                })
            }
        } else {
            keys.forEach(key => {
                if (filters[key].value !== null && filters[key].value.length !== 0) {
                    if (toInt.indexOf(key) !== -1) {
                        payload.filter[key] = parseInt(filters[key].value) 
                    } else if (toCheck.indexOf(key) !== -1) {
                        payload.filter[key] = filters[key].value ? 1 : 0
                    } else {
                        payload.filter[key] = filters[key].value;
                    }
                }
            })
        }

        axios.post(env.api + 'admin/enrollment/list', payload, options)
        .then(response => {
            setFilters(response.data.filter);
            setFiltersLS(response.data.filter)
            setList(response.data.list);
            setOccupation(response.data.occupation);
            setPricelist(response.data.pricelist);
            setPagination({
                pagesize: 20,
                page: 1,
                count: response.data.list.length,
            });
        })
        .catch(error => {
            ErrorResponse(error)
        })        
    }

    const handleFilter = (value, id, type) => {
        let stateFilters = {...filters};
        stateFilters[id].value = value;
        setFilters(stateFilters);
        setFiltersLS(stateFilters)
        if (id === 'region') {
            stateFilters.municipality.value = null;
            getData(false);
        }
    }

    return (
        <div>
            <Filters filters={filters} getData={getData} handleFilter={handleFilter} />
            <Table occupation={occupation} pricelist={pricelist} options={options}/>
            <List list={list} pagination={pagination} />
        </div>
    )
}

export default Institutions