export const Pagination = ({pagination, setPagination}) => {
    if (pagination !== null) {
        const pages = Math.ceil(pagination.count / pagination.pagesize);
        const buttons = []

        if (pages > 1) {
            if (pagination.page >= 4) {
                if (pages > 4) {
                    buttons.push(<button key={"page-1"} className="pagination-button me-2" onClick={() => setPagination(1)}>1</button>)
                    buttons.push(<button key={"page-first-..."} disabled className="pagination-button me-2" onClick={() => {}}>...</button>)
    
                    if (pagination.page > (pages - 3)) {
                        for (let i = pages - 3; i <= pages; i++ ) {
                            buttons.push(<button key={"page-"+i} className={"pagination-button me-2" + (i === pagination.page ? ' active' : '')} onClick={() => setPagination(i)}>{i}</button>)
                        }
                    } else {
                        for(let i = pagination.page - 1; i <= pagination.page + 1; i++) {
                            buttons.push(<button key={"page-"+i} className={"pagination-button me-2" + (i === pagination.page ? ' active' : '')} onClick={() => setPagination(i)}>{i}</button>)
                        }
    
                        buttons.push(<button key={"page-..."} disabled className="pagination-button me-2" onClick={() => {}}>...</button>)
                        buttons.push(<button key={"page-last"} className="pagination-button me-2" onClick={() => setPagination(pages)}>{pages}</button>)
                    }
                } else {
                    for(let i = 1; i <= pages; i++) {
                        buttons.push(<button key={"page-"+i} className={"pagination-button me-2" + (i === pagination.page ? ' active' : '')} onClick={() => setPagination(i)}>{i}</button>)
                    }
                }
            } else {
                let show_buttons;
                if(pages < 4) {
                    show_buttons = pages;
                } else {
                    show_buttons = 4;
                }
    
                for(let i = 1; i <= show_buttons; i++) {
                    buttons.push(<button key={"page-"+i} className={"pagination-button me-2" + (i === pagination.page ? ' active' : '')} onClick={() => setPagination(i)}>{i}</button>)
                }
    
                if(pages > 4) {
                    buttons.push(<button key={"page-last-..."} disabled className="pagination-button me-2" onClick={() => {}}>...</button>)
                    buttons.push(<button key={"page-last"} className="pagination-button me-2" onClick={() => setPagination(pages)}>{pages}</button>)
                }
            }
        }

        return (
            <div>
                Stran {pagination.page} od {pages}
                <div className="pagination-container">
                    <ul className="pagination">
                        {buttons}
                    </ul>
                </div>
            </div>
        );
    }
}