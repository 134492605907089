import React, { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import ProgressBar from '../../hoc/progressBar/ProgressBar'
import { ErrorResponse } from '../../hoc/errorResponse/ErrorResponse'
import { useInstStore, useNotificationStore } from '../../store'
import { date_format } from '../../izUtils'
import AvailableTable from './AvailableTable'
import JoinedTable from '../joinedOccupation/JoinedTable'

const Available = ({options}) => {
    const { id } = useParams()
    const { setInstitution, setHasPricelist, setHasJoined } = useInstStore((state) => ({
        setInstitution: state.setInstitution,
        setHasPricelist: state.setHasPricelist,
        setHasJoined: state.setHasJoined
    }))

    const { setNotification } = useNotificationStore((state) => ({
        setNotification: state.setNotification,
    }))

    const [validOn, setValidOn] = useState(moment(new Date()).toDate())
    const [data, setData] = useState(null)
    const [occupation, setOccupation] = useState(null)

    useEffect(() => {
        getData(true, false, null)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, options])

    const getData = (firstTime, save, passedInputs) => {
        let payload = {}
        if (firstTime) {
            payload = {
                id: parseInt(id),
                validOn: moment(validOn).format()
            }
        } else {
            payload = {
                save,
                ignoreWarnings: false,
                id: parseInt(id),
                hasSections: data.hasSections,
                occupation: {
                    values: []
                }
            }

            const keys = Object.keys(passedInputs) 
            let rows = []
    
            keys.forEach(key => {
                passedInputs[key].forEach(item => {
                    if (item.value !== null && item.value !== '' && item.value.length !== 0 ) {
                        if (!rows[item.rowID]) rows[item.rowID] = []
                        rows[item.rowID].push({
                            ageGroup: item.ageGroup,
                            department: item.department,
                            groupType: item.groupType,
                            column: item.column,
                            value: item.value
                        }) 
                    }
                })
            })
    
            rows.forEach(item => {
                if (item.length > 0) {
                    payload.occupation.values.push(item)
                }
            })
        }

        let method = 'patch'
        if (firstTime) method = 'post'
        axios[method](env.api + 'admin/enrollment', payload, options).then(response => {
            if (firstTime) {
                setInstitution(id)
                setHasPricelist(response.data.hasOwnPricelist)
                setHasJoined(response.data.topInstitution === parseInt(id))
            }

            if (save) {
                if (response.data.updated) {
                    setNotification({ text: "Shranjevanje uspešno", type: 'info' })
                    setTimeout(() => {
                        setNotification(null)
                    }, 3000);
                } else {
                    setNotification({ text: "Shranjevanje se ni izvedlo. Preverite vpisane vrednosti - eno ali več polj ima vpisano neustrezno vrednost", type: 'warning' })
                }
            }

            let columns = {}
            if (response.data !== null && response.data.occupation !== null) {
                response.data.occupation.values.forEach(row => {
                    row.forEach((item) => {
                        if (!columns[item.column]) columns[item.column] = []
                        columns[item.column][item.rowID] = item
                    })
                })
            }

            if (firstTime) setData(response.data)
            setOccupation(columns)
        })
        .catch(error => {
            ErrorResponse(error)
        })
    }

    const handleChange = (value, params) => {
        let cloneOccupation = {...occupation}
        cloneOccupation[params.column][params.rowID].value = value;
        getData(false, false, cloneOccupation)
    }

    const handleHasSection = async () => {
        let proceed = true
        let oldData = {...data}
        if (oldData.hasSections) {
            await Swal.fire({
                title: 'Pozor',
                text: 'Z izbiro "Ne" boste onemogočili vnos podatkov za izbrano enoto in prikazan bo zbirnik podatkov. Ste prepričani, da želite nadaljevati?',
                icon: 'warning',
                confirmButtonText: 'OK',
                showCancelButton: true,
                cancelButtonText: 'Prekliči'
            }).then(value => {
                if (!value.isConfirmed) proceed = false
            })
        }
        
        if (proceed) {
            oldData.hasSections = !oldData.hasSections
            setData(oldData)
        }
    }

    if (data !== null) {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-7">
                        <h3>{data.name}&nbsp;({data.prs + ' ' + data.mss})</h3>
                        <div className="contactInfo">{data.address.string}</div>
                        <div className="contactInfo">Telefonska številka: {data.telephone.length !== 0 ? data.telephone : "/" }, E-mail: {data.email.length !== 0 ? data.email : "/" }</div>
                    </div>
                    <div className="col-md-5">
                        <h3 id="lastChangeLbl" className="text-right-md" style={{ visibility: "visible" }}>Zadnja sprememba: <span>{data.occupation.validSince === null ? '/' : date_format(data.occupation.validSince, 'date')}</span></h3>
                        <div className="form-group pull-right-md">
                            <div className="input-group">
                                <span className="input-group-addon">Prosta mesta na dan:</span>
                                <div className="form-control psv-datepicker hasDatepicker move-calendar" style={{ zIndex: '5' }}>
                                    <DatePicker
                                        className="no-border"
                                        selected={validOn}
                                        onChange={(date) => setValidOn(date)}
                                        dateFormat='dd.MM.yyyy'
                                        calendarStartDay={1}
                                        maxDate={new Date()}
                                        showYearDropdown={true}
                                        yearDropdownItemNumber={15}
                                        scrollableYearDropdown={true}
                                    />
                                </div>
                                <span className="input-group-btn">
                                    <button className="btn btn-primary" onClick={() => getData(true, false, null)}>Prikaži</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-inline col-xs-6">
                        <div className="form-group" style={{ marginBottom: "0" }}>
                            <div className="input-group">
                                <span className="input-group-addon">Enota z oddelki:</span>
                                <select className="form-control" value={data.hasSections} onChange={handleHasSection}>
                                    <option value={true}>Da</option>
                                    <option value={false}>Ne</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 pull-right text-right">
                        <button className="btn btn-primary" onClick={() => getData(false, true, occupation)}>Shrani</button>
                    </div>
                </div>
                <div id="prostaMesta" className='contentBox'>
                    {data.hasSections ?
                        <AvailableTable occupation={occupation} handleChange={handleChange}/> 
                        :
                        <JoinedTable data={data} type="joinedOccupation" /> // type="joinedOccupation" OR type="occupation" ??
                    }
                    <div className="row">
                        <div className="col-sm-12 pull-right text-right">
                            <button className="btn btn-primary" onClick={() => getData(false, true, occupation)}>Shrani</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    } else {
        return <ProgressBar />
    }
}

export default Available